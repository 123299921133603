import { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import readXlsxFile from 'read-excel-file';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchCars } from '../redux/cars/carSlice';
import { useUser, useToken } from './auth';
import ExcelData from './excelData';
import Aclist from './Aclist';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

export default function AddAcgroup() {
  const { state } = useLocation();

  const url = process.env.REACT_APP_BASE_URL;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const [data, setData] = useState([]);
  const [view, setView] = useState({});
  const [message, setMessage] = useState('');

  const { carData, isSuccess } = useSelector((state) => state.cars);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCars(token));
  }, [dispatch, token]);

  async function getExcellData() {
    const input = document.querySelector('#acdata');
    const rows =
      input.files[0] === undefined ? [] : await readXlsxFile(input.files[0]);
    setData(rows);
    //handleClose();
  }

  function closeData() {
    setData([]);
    handleClose();
    setMessage('');
  }

  useEffect(() => {
    const todays = async () => {
      let sdate = '';
      let edate = '';
      if (state) {
        sdate = state.sdate;
        edate = state.edate;
      } else {
        const time = moment().format().substring(0, 10);
        sdate = time;
        edate = time;
      }

      try {
        const datass = JSON.stringify({
          sdate,
          edate,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/acgroup/data`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        setView(data);
      } catch (error) {
        console.log(error);
      }
    };
    todays();
  }, [token, url, state]);

  async function searchByDate() {
    const sdate = document.querySelector('#s-date').value;
    const edate = document.querySelector('#e-date').value;
    try {
      const datass = JSON.stringify({
        sdate,
        edate,
      });
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url}/acgroup/data`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        data: datass,
      };

      const { data } = await axios.request(config);
      setView(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function saveData(datas, cars) {
    const newData = datas.filter((dat) => typeof dat[0] === 'number');
    const dataWithOwner = newData.map((newdat) => {
      const plate = newdat[2] ? newdat[2].toUpperCase() : '';
      const car = cars.carsData.filter((car) => car.plate === plate);
      const carId = Object.keys(car).length === 0 ? 0 : car[0].id;
      const ownerId = Object.keys(car).length === 0 ? 0 : car[0].owner;

      return {
        plate: plate,
        sdate: newdat[4],
        edate: newdat[5],
        tickets: newdat[6],
        amout: newdat[7],
        carId,
        ownerId,
        acId: newdat[1],
        user: id,
      };
      //return [...newdat];
    });
    try {
      setMessage('Please wait....');
      document.querySelector('.smessage').style.color = 'blue';
      const datas = JSON.stringify(dataWithOwner);
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url}/acgroup`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        data: datas,
      };
      const { data } = await axios.request(config);

      if (data.message === 'Data added') {
        setView(data);
        closeData();
        setMessage('');
        document.querySelector('.smessage').style.color = 'black';
      } else {
        setMessage(data.message);
        document.querySelector('.smessage').style.color = 'red';
      }
    } catch (error) {
      console.log(error.response.data);
      setMessage(error.response.data);
      document.querySelector('.smessage').style.color = 'red';
    }
  }

  let carsData = [];
  if (isSuccess) carsData = carData;
  return (
    <>
      <div className="cars-add-btn-dv">
        <input name="s-date" id="s-date" type="date" />
        <input name="e-date" id="e-date" type="date" />
        <Button variant="outline-success" onClick={() => searchByDate()}>
          Search
        </Button>
      </div>
      <Button variant="dark" onClick={handleShow}>
        Add data from AC Group system
      </Button>

      <Modal show={show} onHide={() => closeData()}>
        <Modal.Header closeButton>
          <Modal.Title>ADD Data</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: '900px !important' }}>
          <input type="file" id="acdata" />

          {data.length > 0 && <ExcelData data={data} cars={carsData} />}
          <p className="smessage">{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeData()}>
            Close
          </Button>

          <Button variant="info" onClick={() => getExcellData()}>
            Check data
          </Button>

          {data.length > 0 && (
            <Button variant="danger" onClick={() => saveData(data, carsData)}>
              Save Data
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* listing data */}

      <div style={{ marginTop: '20px' }} className="ac-data">
        <Aclist datas={view} />
      </div>
    </>
  );
}
